import clientImage0 from "../images/clientImage0.png";
import clientImage1 from "../images/clientImage1.png";
import clientImage2 from "../images/clientImage2.png";
import clientImage3 from "../images/clientImage3.png";
import clientImage4 from "../images/clientImage4.png";
import clientImage5 from "../images/clientImage5.png";
import clientImage6 from "../images/clientImage6.png";
import clientImage7 from "../images/clientImage7.png";
import clientImage8 from "../images/clientImage8.png";
import clientImage9 from "../images/clientImage9.png";
import clientImage10 from "../images/clientImage10.png";
import clientImage11 from "../images/clientImage11.png";
import clientImage12 from "../images/clientImage12.png";
import clientImage13 from "../images/clientImage13.png";
import clientImage14 from "../images/clientImage14.png";
import clientImage15 from "../images/clientImage15.png";
import clientImage16 from "../images/clientImage16.jpeg";
import clientImage17 from "../images/clientImage17.jpeg";
import clientImage18 from "../images/clientImage18.jpeg";

export const clientsImages = [
  { image: clientImage18 },
  { image: clientImage17 },
  { image: clientImage16 },
  { image: clientImage15 },
  { image: clientImage14 },
  { image: clientImage13 },
  { image: clientImage12 },
  { image: clientImage11 },
  { image: clientImage10 },
  { image: clientImage9 },
  { image: clientImage8 },
  { image: clientImage7 },
  { image: clientImage6 },
  { image: clientImage5 },
  { image: clientImage4 },
  { image: clientImage3 },
  { image: clientImage2 },
  { image: clientImage1 },
  { image: clientImage0 },
];
